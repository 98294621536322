(function (module) {
  'use strict';
  caseControls.$inject = [];
  function caseControls() {
    return {
      scope: {
        caseObject: '=case',
        isConversation: '@',
        editable: '=editable'
      },
      templateUrl: 'blocks/case/views/case_control.html',
      restrict: 'E',
      controllerAs: 'vm',
      controller: [
        '$scope',
        '$rootScope',
        '$filter',
        '$interpolate',
        '$window',
        'CaseService',
        'AdNotification',
        'ColumnsService',
        'UserService',
        'SlaHandlerService',
        'EstablishmentService',
        'CaseDetailManager',
        'DepartmentService',
        'CustomData',
        'StylesService',
        '$timeout',
        function (
          $scope,
          $rootScope,
          $filter,
          $interpolate,
          $window,
          CaseService,
          AdNotification,
          ColumnsService,
          UserService: UserService,
          SlaHandlerService,
          EstablishmentService: EstablishmentService,
          CaseDetailManager: CaseDetailManager,
          DepartmentService: DepartmentService,
          CustomData: CustomDataService,
          StylesService: StylesService,
          $timeout
        ) {

          const vm = this;
          const translate = message => $filter('translate')(message);
          vm.$onInit = () => {
            const {name = ''} = $scope.caseObject.current_assignment || {};
            const waitAssign = translate('CASECONTROL_CM_WAITING_AGENT');
            const assignToAgent = `${translate(
              'MESSAGECONTROL_ASSIGNED_TO'
            )} ${name}`;

            EstablishmentService.getEstablishment().then(establishment => {
              const {
                config: {enable_queued_assignment: hasLimits}
              } = establishment;
              if (hasLimits && !name) {
                $scope.tooltipCm = waitAssign;
              } else if (name) {
                $scope.tooltipCm = assignToAgent;
              }
              _setDefault(establishment);
            });

            if ($scope.caseObject.department_id) {
              $scope.tooltipTeam = translate('MESSAGECONTROL_ASSIGNED_TO');
            }

            CaseService.setFormattedDates($scope.caseObject);
            _setMainRelatedUser();

          };

          const _setDefault = establishment => {
            $scope.hasSurveyIntegration = establishment
              ? establishment.integrations
              : false;

            $scope.prettyUsername = _getUsername(
              $scope.caseObject.establishment_users[0]
            );
            $scope.showExtra = null;
            $scope.selectedAccount = {model: null};
            $scope.newComment = '';
            $scope.showEPA = establishment
              ? establishment.features.satisfaction_module
              : false;
            UserService.getProfile().then(user => {
              $scope.isAdminOrCM = user.isAdminOrCm;
              $scope.isSupervisor = user.isSupervisor;
              $scope.agentName = `${user.first_name} ${user.last_name}`;
              $scope.agentEmail = user.email;
              $scope.isBot = user.profile.is_bot;
            });
          };

          $scope.isNotEmpty = value => value != null;

          const _getUsername = (user = '') => {
            return user.sn === 'whatsapp' ? user.name : user.uname;
          };

          $scope.adjustMenuPosition = function(evento) {
            $timeout(function() {
              var button = evento.currentTarget;
              var dropdowns = document.querySelectorAll('.dropdown-menu-button.dropdown-case-control');
          
              if (dropdowns.length === 0) return; 
          
              dropdowns.forEach(function(dropdown) {
                  var rightSpace = window.innerWidth - button.getBoundingClientRect().right;
                  var bottomSpace = window.innerHeight - button.getBoundingClientRect().bottom;

                  if (bottomSpace < (dropdown as HTMLElement).offsetHeight) {
                    (dropdown as HTMLElement).style.transform = 'translate(-100%, -100%)';
                    return
                }
                  if (rightSpace < (dropdown as HTMLElement).offsetWidth) {
                      (dropdown as HTMLElement).style.transform = 'translateX(-100%)';
                  }
              });
            });          
          };
          
          const _setMainRelatedUser = () => {
            if ($scope.caseObject) {
              const {users: _users = [], establishment_users: eusers = []} =
                $scope.caseObject;

              if (_users.length > 0) {
                $scope.mainRelatedUser = _users[0];
              } else if (eusers.length > 0) {
                $scope.mainRelatedUser = eusers[0];
              } else {
                $scope.mainRelatedUser = {};
              }
            }
          };

          const _formatColumnData = user => {
            const {sn, text: _text = undefined, uname, resource_id: id} = user;
            const author = {
              id,
              sn
            };
            let name = sn === 'twitter' ? '@' : '';
            name = !_text ? `${name}${uname}` : `${name}${_text}`;

            return {
              author,
              name,
              column_type: 'case'
            };
          };
          const _toggleSentiment = isStart => {
            const {
              resource_id: resourceId,
              sentiment_start,
              sentiment_finish
            } = $scope.caseObject;

            const oldSentiment = isStart ? sentiment_start : sentiment_finish;

            const key = isStart ? 'sentiment_start' : 'sentiment_finish';
            const sentiment =
              oldSentiment === true ? null : oldSentiment === false;

            $scope.disableSentimentButton = true;

            if (isStart) {
              $scope.caseObject['sentiment_start'] = sentiment;
            } else {
              $scope.caseObject['sentiment_finish'] = sentiment;
            }

            const data = {};
            data[key] = sentiment;
            _setCaseSentiment(resourceId, data, isStart, oldSentiment);
          };
          const _setCaseSentiment = (
            resourceId,
            data,
            isStart,
            oldSentiment
          ) => {
            CaseService.setSentiment(resourceId, data).then(
              () => {
                $scope.disableSentimentButton = false;
              },
              data => {
                $scope.disableSentimentButton = false;
                if (isStart) {
                  $scope.caseObject['sentiment_start'] = oldSentiment;
                } else {
                  $scope.caseObject['sentiment_finish'] = oldSentiment;
                }
                const action = isStart
                  ? 'set_sentiment_start'
                  : 'set_sentiment_finish';

                AdNotification.error(data, action);
              }
            );
          };
          const _createUserColumn = data => {
            ColumnsService.postColumn(data).then(
              column => {
                $scope.$emit('addColumn', column);
              },
              data => {
                AdNotification.error(data, 'post_column');
              }
            );
          };

          $scope.createUserColumn = function () {
            _createUserColumn(_formatColumnData($scope.mainRelatedUser));
          };

          $scope.toggleCaseCallback = function (action, updatedCase) {
            $scope.caseObject = updatedCase;
            CaseService.setFormattedDates($scope.caseObject);

            const emitData = {action, element: updatedCase};
            $rootScope.$emit('column:actionOnCase', emitData);
            $rootScope.$broadcast('case:hasBeenUpdated', updatedCase);
            $rootScope.$emit('updateSla', 'update');
          };

          const getPrintInterpolations = async function () {

            const caseId = $scope.caseObject['resource_id'];

            let currentDate = moment()

            const currentYear = currentDate.format('YYYY');

            const reportCreatedDate = currentDate.format('DD/MM/YYYY');
            const reportCreatedTime = currentDate.format('HH:mm:ss');
            
            const caseIdentifier = $scope.caseObject['identifier'];
            const reportCreatedByName = $scope.agentName;
            const reportCreatedByEmail = $scope.agentEmail;
            const caseLink = `${location.origin}/#/inbox/case/${caseIdentifier}`;
            const caseCreationDate = moment($scope.caseObject.created * 1000).format('DD/MM/YYYY HH:mm:ss');
            const caseUpdateDate = moment($scope.caseObject.updated_time * 1000).format('DD/MM/YYYY HH:mm:ss');
            let caseClosedDate = '-';
            if ($scope.caseObject.closed) {
              caseClosedDate = moment($scope.caseObject.closed * 1000).format('DD/MM/YYYY HH:mm:ss');
            };
            const caseStatus = $scope.caseObject.workflow_status.label;

            let caseCurrentAssignament = '-'
            if ($scope.caseObject.current_assignment) {
              caseCurrentAssignament = _.escape($scope.caseObject.current_assignment.name);
            };
            const slaConfig = SlaHandlerService.getSlaConfig($scope.caseObject);
            const caseSla = slaConfig.human;

            const inSlaChecked = $scope.caseObject.in_sla_time? 'checked' : '';
            const caseInSla =`<input type="checkbox" ${inSlaChecked} style="pointer-events: none;">`;

            const importantChecked = $scope.caseObject.important? 'checked' : '';
            const caseIsImportant = `<input type="checkbox" ${importantChecked} style="pointer-events: none;">`

            let caseDepartment = '-';
            let caseAccountUid = '-';
            let caseAccountName = '-';
            let caseSocialNetwork = '-';

            if ($scope.caseObject.establishment_users[0]) {
              caseAccountUid = $scope.caseObject.establishment_users[0].uid;
              caseAccountName = _.escape($scope.caseObject.establishment_users[0].name);
              caseSocialNetwork = $scope.caseObject.establishment_users[0].sn;
            };

            let caseUserUid = '-';
            let caseUserName = '-';
            let caseUserAvatar = '-';
            let caseUserResourceId = '-';

            if ($scope.caseObject.users[0]) {
              caseUserUid = $scope.caseObject.users[0].uid;
              caseUserName = _.escape($scope.caseObject.users[0].name);
              caseUserAvatar = $scope.caseObject.users[0].avatar;
              caseUserResourceId = $scope.caseObject.users[0].resource_id;
            }

            return Promise.all([
              CaseService.get(caseId),
              CaseService.getCaseHistory(caseId),
              CaseService.getMessages(caseId),
              DepartmentService.getDepartments(),
              CustomData.getCustomData(),
              UserService.getUser(caseUserResourceId),
              StylesService.getStyles()
            ]).then(([
                caseObject,
                history,
                messages,
                departments,
                customData,
                user,
                styles
            ]) => {

              let logo = styles.logoLight;

              function getDomainName(hostName) {
                return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
              }

              let domain = getDomainName($window.location.hostname)

              if (domain == 'localhost') {
                domain = 'adere.so'
              }

              $scope.caseObject = caseObject;

              const caseUserLastMessage = user.last_message;

              let suitableCustomData = customData.filter(cd => !cd.deleted && cd.level == 0 && ['text', 'datetime'].includes(cd.type) && cd.visible)

              let caseCustomData = suitableCustomData.filter(cd => cd.entity == 'C');
              let userCustomData = suitableCustomData.filter(cd => cd.entity == 'U');

              if ($scope.caseObject.department_id) {

                caseCustomData = caseCustomData.filter(cd => cd.department_ids.includes($scope.caseObject.department_id));
                userCustomData = userCustomData.filter(cd => cd.department_ids.includes($scope.caseObject.department_id));

                departments.forEach(department => {
                  if ($scope.caseObject.department_id == department.id) {
                    caseDepartment = department.name;
                  };
                });
              };

              let caseCustomDataDivs = ''

              function getCustomDataFromCase(customData, customDatas=[]) {
                if ($scope.caseObject[customData.key]) {
                  return $scope.caseObject[customData.key];
                }
                return '-'
              };

              function getCustomDataDiv(label, value) {
                return `<div class="custom-data">
                  <div class="custom-data-label">
                    ${$filter('translate')(_.escape(label))}:
                  </div>
                  <div class="custom-data-value">
                  ${_.escape(value)}
                  </div>
                </div>`
              };

              caseCustomData.forEach(cd => {
                let value = getCustomDataFromCase(cd);
                caseCustomDataDivs += getCustomDataDiv(cd.label, value);
              })

              let userCustomDataDivs = ''

              function getCustomDataFromUser(user, customData, customDatas=[]) {
                if (user[customData.key]) {
                  return user[customData.key];
                }
                return '-'
              };

              userCustomData.forEach(cd => {
                let value = getCustomDataFromUser(user, cd);
                userCustomDataDivs += getCustomDataDiv(cd.label, value);
              })

              let caseHistoryDivs = '';
              const caseHistoryLength = history.length;

              history.forEach(event => {
                const eventDate = moment(event.created * 1000).format('DD/MM/YYYY HH:mm:ss');
                const eventKind = event.kind;
                let eventDescription = '-'
                const author = event.author;

                if (eventKind == 'STATE_CHANGE_EVENT') {
                  let state = event.extra_info.state;
                  if (state == 'CREATED') {
                    eventDescription = 'Creado';
                  } else if (state == 'CLOSED') {
                    eventDescription = 'Cerrado';
                  } else if (state == 'REOPENED') {
                    eventDescription = 'Re-abierto';
                  };
                  if (author) {
                    eventDescription += ` por ${author}`;
                  }
                } else if (eventKind == 'ASSIGNATION_EVENT') {
                  let assignedTo = event.extra_info.to;
                  if (assignedTo) {
                    eventDescription = `Asignado a ${_.escape(assignedTo.name)}`;
                  }
                } else if (['CASE_TYPIFIED_EVENT', 'USER_TYPIFIED_EVENT'].includes(eventKind)) {
                  eventDescription = `Tipificado por ${_.escape(author)}`;
                };
                caseHistoryDivs += `<div class="history-event"><div class="history-date">${eventDate}:</div>&nbsp;<div class="event-description">${eventDescription}</div></div>`
              });


              let caseMessagesDivs = '';
              const caseMessagesLength = messages.messages.length;

              messages.messages.forEach(message => {
                const messageDate = moment(Number.parseFloat(message.created) * 1000).format('DD/MM/YYYY HH:mm:ss');
                const messageContent = message.text.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/<[^>]+>/g, '').trim();
                const messageAuthor = message.author;

                const from = message.ours? 'ours': 'theirs'

                caseMessagesDivs += `<div class="message ${from}">
                <div class="message-date-and-author">
                  <div class="message-date">
                    ${messageDate}
                  </div>
                  <div class="author">
                    <strong>${messageAuthor.name} (${messageAuthor.uid})</strong>
                  </div>
                </div>
                <div class="content">
                  ${messageContent}
                </div>
              </div>`
              });

              let caseCommentsDivs = '';
              let caseCommentsLength = 0;

              if ($scope.caseObject.comments) {

                caseCommentsLength = $scope.caseObject.comments.length;

                $scope.caseObject.comments.forEach(comment => {
                  const commentDate = moment(Number.parseFloat(comment.created) * 1000).format('DD/MM/YYYY HH:mm:ss');
                  const commentContent = _.escape(comment.text).trim();
                  const commentAuthor = comment.author.name;
                  
                  caseCommentsDivs += `<div class="message">
                  <div class="message-date-and-author">
                    <div class="message-date">
                      ${commentDate}
                    </div>
                    <div class="author">
                      <strong>${commentAuthor}</strong>
                    </div>
                  </div>
                  <div class="content">
                    ${commentContent}
                  </div>
                </div>`
                });
              }

              if (caseCommentsLength == 0) {
                caseCommentsDivs = 'Sin comentarios.'
              }

              return {
                domain,
                logo,
                caseId,
                caseIdentifier,
                reportCreatedDate,
                reportCreatedTime,
                reportCreatedByName,
                reportCreatedByEmail,
                caseLink,
                caseCreationDate,
                caseUpdateDate,
                caseClosedDate,
                caseStatus,
                caseCurrentAssignament,
                caseSla,
                caseInSla,
                caseIsImportant,
                caseDepartment,
                caseAccountName,
                caseAccountUid,
                caseSocialNetwork,
                caseUserUid,
                caseUserName,
                caseUserAvatar,
                caseUserLastMessage,
                caseHistoryDivs,
                caseHistoryLength,
                caseMessagesDivs,
                caseMessagesLength,
                caseCustomDataDivs,
                userCustomDataDivs,
                caseCommentsDivs,
                caseCommentsLength,
                currentYear
              }
            })
          }

          $scope.printCase = function () {
            getPrintInterpolations().then(interpolations => {
              fetch('templates/print/case.html').then(
                (data) => {
                    data.text().then(body => {
                        const interpolator = $interpolate(body);
                        const result = interpolator(interpolations);
                        const newWin = $window.open();
                        newWin.document.write(result);
                        newWin.document.close();
                    }) 
                }
              );
            })
          };

          $scope.printCaseJson = function () {
            const newWin = $window.open();
            const json = `<pre>${JSON.stringify($scope.caseObject, null, 2)}</pre>`
            newWin.document.write(json);
            newWin.document.close();
          };

          $scope.setImportant = function () {
            if (!$scope.isAdminOrCM) {
              return;
            }
            let action = 'set_important';
            let restore = $scope.caseObject.important;
            let status = !$scope.caseObject.important;
            if (!status) {
              action = 'set_unimportant';
            }
            $scope.caseObject.important = status;
            CaseService.important($scope.caseObject['resource_id'], status)
              .then(caseObject => {
                $scope.caseObject.important = caseObject.important;
                $rootScope.$emit('column:actionOnCase', {
                  action: 'updateCase',
                  element: $scope.caseObject
                });
                AdNotification.success(201, action);
              })
              .catch(error => {
                $scope.caseObject.important = restore;
                AdNotification.error(error, action);
              });
          };

          $scope.openSideViewOption = (
            sideViewMenuOption: SideViewMenuOptions
          ) => {
            CaseDetailManager.openCaseState(
              $scope.caseObject['resource_id'],
              undefined,
              sideViewMenuOption
            );
          };

          $scope.createColumn = () => {
            const data = {
              case_id: $scope.caseObject['resource_id'],
              column_type: 'message'
            };

            ColumnsService.postColumn(data).then(
              column => {
                $scope.$emit('addColumn', column);
              },
              data => {
                AdNotification.error(data, 'post_column');
              }
            );
          };

          $scope.toggleSentimentStart = function () {
            if (!$scope.isAdminOrCM) {
              return;
            }
            _toggleSentiment(true);
          };

          $scope.toggleSentimentFinish = function () {
            if (!$scope.isAdminOrCM) {
              return;
            }
            _toggleSentiment(false);
          };

          $scope.mergeDialog = () => {
            if (!$scope.isAdminOrCM) {
              return;
            }
            $scope.$emit('caseDetail:mergeCase');
          };
          const notify = {
            success(message) {
              return AdNotification.notify(message, 'success');
            },
            error(message) {
              return AdNotification.notify(message, 'error');
            }
          };

          $scope.assignCM = function (cm) {
            if (!($scope.isAdminOrCM || $scope.isSupervisor)) {
              console.log('No permission to assign CM: User is not Admin or CM or Supervisor')
              return;
            }
            const caseData = {
              current_assignment: null,
              assigned: false
            };

            if (cm) {
              caseData['current_assignment'] = {cmid: cm.cmid, name: cm.name};
              caseData.assigned = true;
            }
            CaseService.assignCM(
              $scope.caseObject['resource_id'],
              caseData
            ).then(
              caseObject => {
                const emitData = {action: 'assign', element: caseObject};
                $scope.showExtra = undefined;
                $scope.caseObject['current_assignment'] =
                  caseObject['current_assignment'];

                const {current_assignment: currentAssignment} =
                  $scope.caseObject;
                if (currentAssignment !== null) {
                  const CMInitials = CaseService.getInitials(
                    currentAssignment.name
                  );
                  currentAssignment.initials = CMInitials;
                  const {name} = caseObject.current_assignment;
                  notify.success(
                    $filter('translate')('AD_MESSAGE_ASSIGN_CM_OK_WITH_NAME', {
                      name
                    })
                  );
                } else {
                  notify.success($filter('translate')('AD_MESSAGE_UNASSIGNED'));
                }
                $rootScope.$emit('column:actionOnCase', emitData);
              },
              data => {
                AdNotification.error(data, 'assign_cm');
              }
            );
          };
          $scope.assignDepartment = function (department) {
            if (!$scope.isAdminOrCM && !$scope.isSupervisor) {
              return;
            }
            const params = {
              department_id: department?.id || null
            };
            CaseService.assignDepartment(
              $scope.caseObject['resource_id'],
              params
            )
              .then(response => {
                $rootScope.$emit('column:actionOnCase', {
                  action: 'updateCase',
                  element: $scope.caseObject
                });
                if (
                  response &&
                  typeof department === 'object' &&
                  department.id !== null
                ) {
                  // unassigned team

                  $rootScope.waitingAgent = $scope.caseObject;
                  notify.success(
                    $filter('translate')('AD_MESSAGE_ASSIGN_CM_OK_WITH_NAME', {
                      name: department.name
                    })
                  );
                } else {
                  notify.success($filter('translate')('AD_MESSAGE_UNASSIGNED'));
                }
              })
              .catch(() => {
                notify.error(
                  $filter('translate')('AD_MESSAGE_ASSIGN_TEAM_ERROR')
                );
              });
          };

          const caseCommentCancelListenerOff = $scope.$on('caseComments:cancel', () => {
            $scope.showExtra = null;
          });

          $scope.$on('$destroy', () => {
            caseCommentCancelListenerOff();
          });

          $scope.successCopying = () => {
            AdNotification.success(200, 'case_copy_to_clipboard');
          };
        }
      ]
    };
  }
  module.directive('caseControls', caseControls);
})(angular.module('postCenterWebClientApp'));
