app.filter('unescapeString', function ($sce) {
    return function (input: string): string {
        return _.unescape(input)
    };
});

app.filter('escapeString', function ($sce) {
    return function (input: string): string {
        return _.escape(input)
    };
});